import { render, staticRenderFns } from "./NoteViewer.vue?vue&type=template&id=2752e87e&"
import script from "./NoteViewer.vue?vue&type=script&lang=js&"
export * from "./NoteViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports